import React from "react"
import styled from "@emotion/styled"
import { color, space, fontSize } from "styled-system"
import { IconContext } from "react-icons"
import { graphql, Link,useStaticQuery  } from "gatsby"
import BackgroundImage from 'gatsby-background-image'

import {
  FaUtensils,
  FaGlobeEurope,
  FaBook,
  FaRunning,
  FaDice,
  FaChevronDown,
} from "react-icons/fa"


const Content = styled.div`
  font-size: 1.2rem;
`

// const NavLink = styled(Link)`
//   margin: 0px 10px;
//   text-decoration: none;
//   position: relative;
// `

const SiteHeader = styled.header`
  background: transparent;
  align-content: center;
  justify-content: center;
  text-align: center;
`
const NavLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  flex: 2 50%;
  ${space}
  ${color}
`

const HomeLink = styled(NavLink)`
  margin-left: 0;
`

const MainCardContainer = styled("div")`
  display: flex;
  min-height: 100vh;
  ${color}
  ${fontSize}
`

const Logo = styled(Link)`
  position: absolute;
  margin-top: 60px;
  left: 50%;
  height: 200px;
  width: 190px;
  transform: translate(-50%, -50%);
  text-decoration: none;
  color: inherit;
`

const Subtext = styled("div")`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 200px;
  width: 80%;
  transform: translate(-50%, -50%);
  text-decoration: none;
  font-size: 18px;
  color: inherit;
`

const NavArrow = styled("h2")`
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.7em;
`

const MainCard = ({ className }) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(absolutePath: { regex: "/howth_blue_laptop*/" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  const imageData = data.desktop.childImageSharp.fluid

  return (
    <BackgroundImage
      Tag="section"
      className={className}
      fluid={imageData}
      backgroundColor={`#040e18`}
    >
      <MainCardContainer>  
        <Logo to="/blog/">
          <p style={{ fontSize: "6em", marginBottom: "-0.3em" }}>40</p>
          <p style={{ fontSize: "1.7em" }}>THINGS</p>
        </Logo>
        <Subtext>
          “I had a clear mind. I felt sore, but the good kind. I was cold and damp from the earlier rain, but the coffee was hot and the chowder warmed me up. The contrasts made me feel even more alive than a normal day. I can still recall the distinct sensations, even to this day.
          <br />
          <br />
          I want to experience more days - more moments - like that one. So I started 40 things."
        </Subtext>
        <NavArrow>
          <NavLink
            m={[1, 2, 3]}
            p={[1, 2, 3]}
            title="40 meals"
            to="/blog/"
            style={{ fontSize: "2em" }}
          >
            <FaChevronDown color={color} />
          </NavLink>
        </NavArrow>
      </MainCardContainer>
    </BackgroundImage>
  )
  }
  

const Header = ({ title, isHome }) => (
  <SiteHeader>
    <Content>
      {!isHome ? (
        <div>
          <h3>
            <HomeLink to="/">{title}</HomeLink>
          </h3>
          <h3>
            <IconContext.Provider value={{ color: color, size: "1em" }}>
              <NavLink title="40 meals" to="/blog/meals">
                <FaUtensils />
              </NavLink>
              <NavLink title="400 miles" to="/blog/miles">
                <FaRunning />
              </NavLink>
              <NavLink title="40 books" to="/blog/books">
                <FaBook />
              </NavLink>
              <NavLink title="40 books" to="/blog/games">
                <FaDice />
              </NavLink>
              <NavLink title="40 places" to="/blog/places">
                <FaGlobeEurope />
              </NavLink>
            </IconContext.Provider>
          </h3>
        </div>
      ) : (
        <MainCard />
      )}
    </Content>
  </SiteHeader>
)


export default Header
