import React from "react"
import Header from "../components/header"
import styled from "@emotion/styled"
import { color, fontSize } from "styled-system"

import DinPro from "../../static/assets/DINPro-Medium_13936.woff"

import { FaInstagram } from "react-icons/fa"
import { IconContext } from "react-icons"

const Wrapper = styled("div")`
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  overflow-x: hidden;
  @font-face {
    font-family: "DINPro-Medium";
    font-style: normal;
    font-weight: normal;
    src: local("DINPro-Medium"),
      url(${DinPro}) format("woff");
  }
  font-family: "DINPro-Medium";
  ${color}
  ${fontSize}
`

const Footer = styled.footer`
  text-align: center;
`

class Layout extends React.Component {
  render() {
    const { children, title, isHome } = this.props
    return (
      <Wrapper bg="dark" color="light">
        <Header title={title} isHome={isHome}></Header>
        <main>{children}</main>
        {!isHome && (
          <Footer>
            <h3>
              <IconContext.Provider value={{ color: "gray", size: "2em" }}>
                <a href={`https://www.instagram.com/40thingsblog`}>
                  <FaInstagram />
                </a>
              </IconContext.Provider>
            </h3>
            © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
          </Footer>
        )}
      </Wrapper>
    )
  }
}

export default Layout
